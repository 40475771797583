.jiao {
    width: 106rem;
}

.jiao.rt {
    margin-left: auto;
    transform: rotateY(180deg);
}

.lb {
    transform: rotateX(180deg);
}

.rb {
    margin-left: auto;
    transform: rotate(180deg);
}

.type-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16rem;
}

.type-item text{
    writing-mode: vertical-rl;
    text-orientation: upright;
    position: absolute;
    margin-top: -9rem;
    color: #b37a62;
    letter-spacing: 11.25rem;
    z-index: 1;
    font-size: 26.25rem;
}


