.bimg {
    /* max-width: 100vw;
    max-height: 100vh; */
}
.slick-slide{
    height: 100vh!important;
    justify-content: center;
    align-items: center;
}
:where(.css-dev-only-do-not-override-1hpnbz2).ant-carousel .slick-initialized .slick-slide {
    display: flex!important;
}