.mytable th, .mytable td, .mytable .ant-table {
    background: transparent!important;
    color: #b11f1e!important;
    font-weight: bold;
    font-size: 黑体, 幼圆;
    font-size: 26rem;
}

.mytable th {
    border-bottom: 2rem solid #b11f1e!important;
    border-left: 2rem solid #b11f1e!important;
}
.mytable th:first-child{
    border-left: 0!important;
}
.mytable td {
    padding: 6rem 32rem!important;
}
