.ath-btn {
    background: rgb(110, 55, 1);
    color: white;
    font-size: 32rem;
    line-height: 40rem;
    padding: 22rem 44rem;
    border-radius: 60rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ath-btn img {
    margin-right: 16rem;
}