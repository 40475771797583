.product-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product-item {
    width: 158rem;
    height: 264rem;
    position: relative;
    margin-left: 14rem;
    margin-bottom: 14rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.product-item:nth-child(4n+1) {
    margin-left: 0;
}
.info-text {
    font-size: 26rem;
    margin: 0 26rem;
    color: rgb(194, 68, 49);
}
.info-tit {
    font-weight: bold;
    color: #333;
}
