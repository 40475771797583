.anc-product-item {
    margin-left: 33rem;
    margin-right: 14rem;
    margin-top: 40rem;
}
.anc-product-item:nth-child(4n+1) {
    margin-left: 14rem;
}
.anc-tri-input-papa {
    flex: 1;
    margin-left: 24rem;
}
.anc-tri-input-papa input {
    font-size: 26rem;
    color: #333;
}
.anc-tri-input-papa input::placeholder {
    color: #333;
}
.anc-tri-price-papa input {
    font-size: 26rem;
    color: #b47b63;
    text-align: center;
}
.anc-tri-price-papa input::placeholder {
    color: #b47b63;
}
.anc-price-item{
    margin-left: 44rem;
}
.anc-price-item:first-child{
    margin-left: 0;
}


