.tomb-box {
    position: relative;
    width: 671rem;
    margin: auto;
    background-color: white;
    margin-top: 36rem;
  }
  
  .tomb-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22rem 0;
  }
  
  .tomb-box:before {
    content: '';
    width: 100%;
    height: 36rem;
    background: url('https://www.kaisx.com/miniapp/images/tomb-box.jpg');
    background-size: 100% 100%;
    position: relative;
    display: block;
  }
  
  .tomb-box:after {
    content: '';
    width: 100%;
    height: 36rem;
    background: url('https://www.kaisx.com/miniapp/images/tomb-box.jpg');
    background-size: 100% 100%;
    position: relative;
    transform: rotateX(180deg);
    display: block;
  }
  .tomb-box .tomb-content{
    background: url('https://www.kaisx.com/miniapp/images/tomb-back.jpg');
    background-size: 100% auto;
    padding: 6rem 48rem;
  }
  
  .input-box-papa {
    margin-bottom: 28rem;
  }
  
  
  
  